import { Component, effect, Input, OnDestroy, OnInit } from "@angular/core";
import { LoadingController } from "@ionic/angular";
import { Product, TypeProduct } from "src/app/models/product";
import { Subscription } from "rxjs";
import { SpecialProductBase } from "./special-product-base/special-product-base";

@Component({
  selector: "app-products",
  templateUrl: "./products.component.html",
  styleUrls: ["./products.component.scss"],
})
export class ProductsComponent extends SpecialProductBase implements OnInit, OnDestroy {
  @Input({ required: true }) type: TypeProduct;

  @Input() products: Product[];

  isPP = false;

  subscription: Subscription;

  swiperConfig = {
    slidesPerView: "auto",
    spaceBetween: 30,
    slidesPerGroup: 1,
    loop: false,
    mousewheel: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  };

  constructor(public loadingCtrl: LoadingController) {
    super();
    effect(() => {
      this.updateProductsByType();
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.listenCartChanges();
    this.updateProductsByType();
    this.listenLoading();
    this.isPP = this.handleIsPP();
  }

  ionViewWillEnter() {
    this.isPP = this.handleIsPP();
  }

  listenLoading() {
    this.loadingService.loadingCart$.subscribe((res) => {
      if (!res) {
        if (this.loading) {
          this.loading.dismiss();
        }
      }
    });
  }

  listenCartChanges() {
    this.subscription = this.cart.updateCart$.subscribe(() => {
      if (this.type === "cart") {
        this.products = this.cart.data.cartProducts ?? [];
        this.ref.detectChanges();
      } else {
        this.cart.getProducts();
      }
    });
  }

  updateProductsByType() {
    if (this.type === "products") {
      this.products = this.cart.products() ?? [];
    } else if (this.type === "recommended" && !this.isPP) {
      this.products = this.cart.recommendedProducts() ?? [];
    } else if (this.type === "recommended" && this.isPP) {
      this.products = this.cart.recommendedPointsProducts() ?? [];
    } else if (this.type === "cart") {
      this.products = this.cart.data.cartProducts ?? [];
    }
    this.ref.detectChanges();
  }

  removeItem(ev) {
    console.log("remover item: ", ev);
    if (ev && ev.product) {
      const product: Product = ev.product;
      this.system.showAlert(
        "none",
        "Atenção",
        `Tem certeza que deseja remover <b>${product.label}</b> do carrinho?`,
        "Sim",
        async () => {
          this.loading = await this.loadingCtrl.create({});
          this.loading.present();
          this.cart.removeItem(product);
        },
        "Não",
        () => {}
      );
    }
  }
}
